import React, { useState } from 'react';
import './contactus.css';
//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
    
        
        
function Contactuspage() {
    const loc = window.location;
  const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
  const dynamicgetbooks = '/send-email';
  const getbooksurl = baseUrl + dynamicgetbooks;

    const [details, setDetails] = useState({
        username: "",
        surname: "",
        phonenum: "",
        email: "",
        message: ""
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        /*const fetchContents = async () => {
          //await dispatch(getOutlines(parsedObj));
          await fetch(getbooksurl,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ details })
          })
            .then(response => response.json())
            .then(data => {
              console.log("data contents fetched books db",data)
              
    
              //setContentData(data);
              
            
          });
          
        };
    
        fetchContents();*/
        
        try {
          const response = await fetch(getbooksurl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(details)
          });
          if (response.ok) {
            alert('Email sent successfully!');
            setDetails({
                username: "",
                surname: "",
                phonenum: "",
                email: "",
                message: ""
            });
          } else {
            alert('Failed to send email. Please try again later.');
          }
        } catch (error) {
          console.error('Error:', error);
          alert('An error occurred. Please try again later.');
        }
      };
    return (
        <div className="contactuspage">
            <div className='contactusmap'>
                <div className="contactarea">
                    <iframe title='silwanemap' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1873.078347659257!2d28.591039842387477!3d-20.12750938298302!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1eb5549aa8f5e64b%3A0x78deda1cc22703fe!2sNorth%20End%20Shopping%20Complex%2C%20Panton%20St%2C%20Bulawayo%2C%20Zimbabwe!5e0!3m2!1sen!2snl!4v1714144124660!5m2!1sen!2snl" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>

            <div className='contactusdetails'>
                <div className='contthedetails'>
                    <h2>Contact Us</h2>
                    <div className='contusunderline'></div>
                    <div className='cpageformdetails'>
                        <p>Please use the following contact details to get in touch or fill in the form below</p>
                        <p className='emailnum'><i className="fas fa-envelope"></i>silwaneeducationaltours@gmail.com</p>
                        <p className='phoneenum'><i className="fas fa-phone"></i>Silwane:+263719223395</p>   
                    </div>
                    <form onSubmit={handleSubmit}>
                      <input type="text" className='Nameres' placeholder='Name' value={details.username} onChange={(e) => setDetails({...details, username: e.target.value})} required/>
                      <input type="text" className='Surnameres' placeholder='Surname' value={details.surname} onChange={(e) => setDetails({...details, surname: e.target.value})} required/>
                      <input type="text" className='Telres' placeholder='Telephone' value={details.phonenum} onChange={(e) => setDetails({...details, phonenum: e.target.value})} required/>
                      <input type="text" className='Emailres' placeholder='Email'value={details.email} onChange={(e) => setDetails({...details, email: e.target.value})} required/>
                      <textarea className='Messageres' placeholder='Your Message'value={details.message} onChange={(e) => setDetails({...details, message: e.target.value})} required/>

                      <button className='subcontactinfo'>Submit</button>
                    </form>

                </div>

            </div>
            
            
             
        </div>
    );
}
        
        
export default Contactuspage;
        