import React, { useState, useEffect } from 'react';
import './productcara.css';

const ProductCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const images = [
    {id: 1, img :'https://ucarecdn.com/7678005f-1288-4cb1-a2a2-ea5b7ad880d5/-/preview/635x960/'},
    {id: 2, img :'https://ucarecdn.com/bbf3b18a-3f3b-4eba-ac52-0cea7edf0e04/-/preview/635x960/'},
    {id: 3, img :'https://ucarecdn.com/4fc395a9-c75a-4ee2-86a1-68a3104020d4/-/preview/720x960/'},
    {id: 4, img :'https://ucarecdn.com/387227ef-0f5d-45f2-885d-8c34f59fa744/-/preview/750x1000/'},
    {id: 5, img :'https://ucarecdn.com/66e11f2a-370d-4f81-8d1c-24ecf1448d72/-/preview/720x960/'},
    {id: 6, img :'https://ucarecdn.com/b5639732-c872-4441-be4e-68ef34b66b23/-/preview/720x960/'},
    
    {id: 7, img :'https://ucarecdn.com/5f984974-a4c2-4abd-bd2c-6bafe56a137f/-/preview/720x960/'},
    {id: 8, img :'https://ucarecdn.com/c130a26f-8af2-41ea-8749-5a2209809977/-/preview/720x960/'},
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex < 5) {
        setCurrentIndex((prevIndex) => (prevIndex + 1));
      }else{
        setCurrentIndex(0);
      }
      
    }, 5000);

    return () => clearInterval(interval);
  }, [images, currentIndex]);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? 0  : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 8 - 3 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="productcarousel">
        <div className="productimgblock">

            {images.map((image, index) => (
                
                <div className='bufferactprodimg' key={image.id}>
                    {(index > (currentIndex - 1) && index < (currentIndex + 3)) &&
                        <div className='actualproductimg' >
                            <img src={image.img} alt={`view ${index + 1}`}/>
                        </div>
                    }
                </div>
                
            ))}

            <button className="prodprevbutton" onClick={prevSlide}>
                &#10094;
            </button>
            <button className="prodnextbutton" onClick={nextSlide}>
                &#10095;
            </button>
        </div>
    </div>
  );
};

export default ProductCarousel;
