import React from 'react';

import '../navbar/navbar.css';
import Navbar from '../navbar/navbar';

import '../aboutus/aboutus.css';
import Aboutuspag from '../aboutus/aboutus';

import '../aboutus/banner.css';
import Bannersect from '../aboutus/banner';

import '../navbar/footer.css';
import Footer from '../navbar/footer';




function Aboutus() {
    return (
        <>
            <Navbar />

            <Bannersect />

            <Aboutuspag />

            <Footer />

        </>
    )
}

export default Aboutus;