import React from 'react';
import './experience.css';
//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
 // https://ucarecdn.com/ee2fe30b-65ae-400c-8a78-e79007cae016/-/preview/500x500/-/quality/smart/-/format/auto/      
//const banner = 'https://ucarecdn.com/87b1fe31-b4ab-4720-8b58-a53294f994c7/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
const wildlife = 'https://ucarecdn.com/f35242c3-ecf6-4261-848b-1cbf3c42c13f/-/preview/500x500/-/quality/smart/-/format/auto/';
const wilderness = 'https://ucarecdn.com/2c941ba1-a37c-4867-b035-61367d9cbdcf/-/preview/1000x666/';
const curdbike = 'https://ucarecdn.com/21ac842a-e1c9-44ab-90fc-31d3ee3a9668/-/preview/500x500/-/quality/smart/-/format/auto/';

/*
     <div className='experiencepgbanner'>
                <img src={banner} alt="banner" />
            </div>
*/
        
        
function Experiencespage() {
    return (
        <div className="experiencespage">
           
            <div className='experienceqoute'>
                <div className='exqoutesect'>
                    <p>“The beauty of Africa is not man-made, it’s nature’s gift to humanity.”</p>
                    <p>Paul Oxon</p>
                </div>
            </div>
            <div className='ExperienceSect'>
                <div className='wildlife'>
                    <div className='wildlifeimg'>
                        <img src={wildlife}  alt="wildlife" /> 
                    </div>
                    <div className='wildlifecontent'>
                        <h2>Wildlife Adventure</h2>
                        <p>Embark on an unforgettable wildlife adventure at Silwane Nature Reserve], where every moment promises discovery and wonder. Join our expert guides as they lead you on immersive expeditions through the heart of our pristine wilderness, where every trail holds the potential for thrilling encounters with some of nature's most captivating creatures. Whether you're tracking elusive predators, observing rare migratory birds, or simply soaking in the sights and sounds of the natural world, each excursion offers a chance to forge a deeper connection with the wildlife that calls this place home.</p>
                        <p></p>
                    </div>
                </div>
                <div className='wildernesswalks'>
                    <div className='wildernesswalksimg'>
                        <img src={wilderness}  alt="wilderness" /> 
                    </div>
                    <div className='wildernesscontent'>
                        <h2>Wilderness Walks & Trails</h2>
                        <p>Step off the beaten path and into the heart of nature's embrace with our wilderness walks and trails at Silwane nature reserve. Meander along winding paths that weave through ancient forests, where sunlight filters through the canopy, dappling the forest floor in a mesmerizing dance of light and shadow. With trails ranging from leisurely strolls to challenging hikes, there's something for every adventurer to discover as they immerse themselves in the untamed beauty of our reserve.</p>
                        <p></p>
                    </div>
                </div>
                <div className='curdbike'>
                    <div className='curdbikeimg'>
                        <img src={curdbike}  alt="curdbike" /> 
                    </div>
                    <div className='curdbikecontent'>
                        <h2>Curd Bike</h2>
                        <p>Embark on a unique adventure and explore the wonders of Silwane nature reserve aboard a rugged curd bike! Designed to traverse even the most challenging terrain with ease, our curd bikes offer visitors an exhilarating way to experience the beauty of our reserve like never before. Set out on winding trails that wind through dense forests, where the crisp mountain air fills your lungs and the scent of pine envelops you in its embrace. With the freedom to roam far and wide, you'll uncover hidden gems and breathtaking vistas around every bend.</p>
                        <p>Whether you're an experienced cyclist or a novice explorer, our curd bikes provide a thrilling opportunity to connect with nature on a deeper level. Feel the adrenaline course through your veins as you navigate rocky paths and steep inclines, immersing yourself in the untamed beauty of the wilderness. With the wind in your hair and the call of the wild echoing in your ears, a curd bike adventure at Silwane nature resrve promises unforgettable memories and a newfound appreciation for the natural world.</p>
                    </div>
                   
                </div>

            </div>
         
             
        </div>
    );
}
        
        
export default Experiencespage;
        