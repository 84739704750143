import React from 'react';

import '../navbar/navbar.css';
import Navbar from '../navbar/navbar';

import '../gallerypage/gallery.css';
import Gallerypage from '../gallerypage/gallery';

import '../navbar/footer.css';
import Footer from '../navbar/footer';




function Gallery() {
    return (
        <>
            <Navbar />

            <Gallerypage />

            <Footer />

        </>
    )
}

export default Gallery;