import React from 'react';

import '../navbar/navbar.css';
import Navbar from '../navbar/navbar';

import '../navbar/carasoule.css';
import Carasoule from '../navbar/carasoule';

import '../home/home.css';
import Homepage from '../home/home';

import '../navbar/footer.css';
import Footer from '../navbar/footer';




function Home() {
    return (
        <>
            <Navbar />

            <Carasoule />

            <Homepage />

            <Footer />

        </>
    )
}

export default Home;