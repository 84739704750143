import React  from 'react';
//import { Link } from 'react-router-dom';
import './imagegrid.css';



const img1 = "https://ucarecdn.com/58a8dbfe-cf20-4147-8057-2ee313bae014/-/preview/1000x750/";
const img2 = "https://ucarecdn.com/e8fa13f1-5e3a-4285-b827-9337cbf7502c/-/preview/500x500/-/quality/smart/-/format/auto/";
const img3 = "https://ucarecdn.com/8936a718-dc6c-44b4-85e7-d32e985c48bd/-/preview/500x500/-/quality/smart/-/format/auto/";
const img4 = "https://ucarecdn.com/aaa11074-f1cb-4af4-9f99-b1136fe2b0ec/-/preview/500x500/-/quality/smart/-/format/auto/";

const Imagegrid = () => {
  

  return (
    <div className="imagegridsect">
        <div className='image1'>
            <img src={img1} alt="Canoening" />
            <div className='labelitem'>
                <p>Canoening</p>
            </div>
        </div>
        <div className='image2'>
            <img src={img2} alt="Watefalls" />
            <div className='labelitem'>
                <p>Watefalls</p>
            </div>
        </div>
        <div className='image3'>
            <img src={img3} alt="Nature" />
            <div className='labelitem'>
                <p>Nature</p>
            </div>
        </div>
        <div className='image4'>
            <img src={img4} alt="Wildlife" /> 
            <div className='labelitem'>
                <p>Wildlife</p>
            </div> 
        </div>
      
    </div>
  );
};
  
  export default Imagegrid;
  
  