//import React, {useState, useEffect} from 'react';
//import { Link } from 'react-router-dom';
//import { Button } from './Button';
//import { NavLink } from "react-router-dom";

/*<div className="dropdown">
        <a href="#">About Us</a>
       
          <div className="dropdown-content">
            <a href="#">Our Purpose</a>
            <a href="#">Our Story</a>
          </div>
        </div>*/

import React, {useState} from 'react';
import './navbar.css';
//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL

const logo = 'https://ucarecdn.com/6ad6da36-90f9-4700-aa14-04552f140096/-/preview/489x484/'; // Replace with your actual logo image URL
//https://ucarecdn.com/6ad6da36-90f9-4700-aa14-04552f140096/-/preview/489x484/
//https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/


function NavBar() {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="navbar">
        <div className="mainlinksimg">
          < img src={logo} alt="Logo" className="logo" />
        </div>
        <div className={`mainlinks ${showMenu ? 'show-menunav' : ''}`}>
          <div className={`close-iconav ${showMenu ? 'show-iconav' : ''}`} onClick={toggleMenu}>
          &times;
          </div>
          <a href="/">Home</a>
          
          <a href="/aboutus">About Us</a>
        
          <a href="/experiences">Experiences</a>
        
          <a href="/bookings">Bookings</a>
        
          <a href="/gallery">Gallery</a>
        
          <a href="/contactus">Contact</a>

         
        </div>
        <div className={`menu-iconav ${showMenu ? 'hide-iconav' : ''}`} onClick={toggleMenu}>
          &#9776;
        </div>
       
      </div>
  );
}


export default NavBar;

  