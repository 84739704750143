import React from 'react';
import './bookings.css';
//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
        
//const banner = 'https://ucarecdn.com/87b1fe31-b4ab-4720-8b58-a53294f994c7/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
//const wildlife = 'https://ucarecdn.com/f35242c3-ecf6-4261-848b-1cbf3c42c13f/-/preview/500x500/-/quality/smart/-/format/auto/';
const reservationimg = 'https://ucarecdn.com/fd0dbb1a-bbb2-42e4-bf36-956bd00881d9/-/preview/1000x750/';
//const curdbike = 'https://ucarecdn.com/21ac842a-e1c9-44ab-90fc-31d3ee3a9668/-/preview/500x500/-/quality/smart/-/format/auto/';

/*
<div className='bookingpgbanner'>
                <img src={banner} alt="banner" />
            </div>

<button className='booknow'>book now</button>
*/
        
        
function Bookingspage() {
    return (
        <div className="bookingpage">
            
            <div className='bookingqoute'>
                <div className='bkqoutesect'>
                    <p>“The beauty of Africa is not man-made, it’s nature’s gift to humanity.”</p>
                </div>
            </div>
            <div className='reservationsect'>
                <h2>Reservations</h2>
                <div className='reservationimg'>
                    <img src={reservationimg} alt="reservation" />
                </div>
                <div className='reservationdetails'>
                    <div className='reservationinfo'>
                        <h2>Silwane sight seeing</h2>
                        <div className='reservunderline'></div>
                        <p>Rates are for entry at Silwane Nature Reserve.</p>

                        <div className='reverstable'>
                            <div className='adltsect'>
                                <p>Per Adult</p>
                            </div>
                            <div className='adltprice'>
                                <p>$3.00usd</p>
                            </div>
                            <div className='childsect'>
                                <p>Per Child</p>
                            </div>
                            <div className='childprice'>
                                <p>$1.00usd</p>
                            </div>
                            <div className='carsect'>
                                <p>Vehicle</p>
                            </div>
                            <div className='carprice'>
                                <p>$1.00usd</p>
                            </div>
                        </div>

                    
                    </div>
                </div>
            </div>
             
        </div>
    );
}
        
        
export default Bookingspage;
        