import React ,{ useState } from 'react';
import './footer.css';
//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
        
const logo = 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
const logosilwane = 'https://ucarecdn.com/6ad6da36-90f9-4700-aa14-04552f140096/-/preview/489x484/';
        
        
function Footer() {
  const loc = window.location;
  const baseUrl = `${loc.protocol}//${loc.hostname}${loc.hostname === 'localhost' ? ':5000' : ''}`;
  const dynamicgetbooks = '/send-email';
  const getbooksurl = baseUrl + dynamicgetbooks;

    const [details, setDetails] = useState({
        username: "",
        surname: "",
        phonenum: "",
        email: "",
        message: ""
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        /*const fetchContents = async () => {
          //await dispatch(getOutlines(parsedObj));
          await fetch(getbooksurl,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ details })
          })
            .then(response => response.json())
            .then(data => {
              console.log("data contents fetched books db",data)
              
    
              //setContentData(data);
              
            
          });
          
        };
    
        fetchContents();*/
        
        try {
          const response = await fetch(getbooksurl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(details)
          });
          if (response.ok) {
            alert('Email sent successfully!');
            setDetails({
                username: "",
                surname: "",
                phonenum: "",
                email: "",
                message: ""
            });
          } else {
            alert('Failed to send email. Please try again later.');
          }
        } catch (error) {
          console.error('Error:', error);
          alert('An error occurred. Please try again later.');
        }
      };
    

    return (
        <div className="footer">
            <div className='footerlogo'>
                <img className='silwanelogo' src={logosilwane} alt="Silwane Logo"/>
                
            </div>
            <div className='footerdetails'>
                <div className='ftabtus'>
                    <h3>About Us</h3>
                    <p>At Silwane Education tours and safaris, we believe that education extends far beyond the classroom walls. Our mission is to provide immersive and enriching experiences that blend education with exploration, fostering a deeper understanding and appreciation of the world around us.</p>
                </div>

                <div className='footerlinks'>
                    <h3>Link</h3>
                    <p><a href='/aboutus'>About Us</a></p>
                    <p><a href='/experiences'>Experiences</a></p>
                    <p><a href='/bookings'>Bookings</a></p>
                    <p><a href='/gallery'>Gallery</a></p>
                    <p><a href='/contactus'>Contact Us</a></p>
                </div>

                <div className='enquire'>
                    <h3>Enquire</h3>
                    <form onSubmit={handleSubmit}>
                    <input className='enName' placeholder='Name' value={details.username} onChange={(e) => setDetails({...details, username: e.target.value})} required/>
                    <input className='enTel' placeholder='Tel' value={details.phonenum} onChange={(e) => setDetails({...details, phonenum: e.target.value})} required/>
                    <input className='enEmail' placeholder='Email' value={details.email} onChange={(e) => setDetails({...details, email: e.target.value})} required/>
                    <textarea className='enMessage' placeholder='Message' value={details.message} onChange={(e) => setDetails({...details, message: e.target.value})} required></textarea>
                    <button>Send</button>
                    </form>
                </div>

                <div className='officehrs'>
                    <h3>Office Hours</h3>
                    <p>Plot 7 <br></br> Northend Shopping Center <br></br><br></br>Monday – Saturday: 8am – 5pm</p>
                    <img className='reservelogo' src={logo} alt="Nature Reserve Logo"/>
                </div>

            </div>
             
        </div>
    );
}
        
        
export default Footer;
        
          