import React from 'react';
import './aboutus.css';
//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
//https://scontent-ams2-1.xx.fbcdn.net/v/t39.30808-6/241194792_1604791279851848_5898187860639475142_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=5f2048&_nc_ohc=6VgSX-nkqN8Ab4q24Zk&_nc_ht=scontent-ams2-1.xx&oh=00_AfA85FjpR_iMcCGGF9tSzH2lT4sgyRFcradSBUvIecKbAw&oe=662C2423       
//https://ucarecdn.com/87b1fe31-b4ab-4720-8b58-a53294f994c7/-/preview/500x500/-/quality/smart/-/format/auto/

const abtimg = 'https://ucarecdn.com/aaa11074-f1cb-4af4-9f99-b1136fe2b0ec/-/preview/500x500/-/quality/smart/-/format/auto/';
        
        
function Aboutuspage() {
    return (
        <div className="Aboutuspage">
            <div className='abtusqoute'>
                <div className='qoutesect'>
                    <p>Silwane is a place of history, culture, wilderness, and wildlife. Its purpose is to protect and preserve both the wilderness and its local communities for generations to come. Silwane a place where culture meets nature.</p>
                </div>
            </div>
            <div className='abtuspgabtussect'>
                <div className='abtuspgimg'>
                    <img src={abtimg} alt="about us" />
                </div>

                <div className='abtuspgpara'>
                    <h3>Silwane Nature Reserve</h3>
                    <p>Nestled in the heart of Zimbabwe, Silwane nature reserve is a sanctuary for both wildlife and weary souls alike. Spanning 500, 000  ha of pristine wilderness, our reserve boasts a diverse tapestry of ecosystems, from lush forests to meandering rivers and expansive meadows.</p>
                    <p>But Silwane Nature reserve is more than just a refuge for flora and fauna; it's a haven for visitors seeking solace and connection with nature. Whether you're an avid birdwatcher, a photography enthusiast, or simply looking to escape the hustle and bustle of city life, our trails, observation points, and guided tours offer endless opportunities for exploration and discovery.</p>
                    <p>Join us at Silwane Nature Reserve and reconnect with the beauty and wonder of the natural world. Immerse yourself in the sights, sounds, and scents of the wilderness, and let the tranquility of our sanctuary rejuvenate your spirit. Welcome home to Silwane.</p>
                </div>

            </div>
            
             
        </div>
    );
}
        
        
export default Aboutuspage;
        
          