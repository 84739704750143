import React  from 'react';
//import { Link } from 'react-router-dom';
import './videogrid.css';



const Homepage = () => {

    const video1 = "https://www.youtube.com/embed/4x5Z3mIVACw";
  
  return (
    <>
    <div className="homevideogrid">
        <div className="videocont">
            <iframe title='silwanevid' src={video1}></iframe>
        </div>
    </div>

    </>
  );
};
  
export default Homepage;