import React from 'react';

import '../navbar/navbar.css';
import Navbar from '../navbar/navbar';

import '../contactus/contactus.css';
import Contactuspage from '../contactus/contactus';

import '../navbar/footer.css';
import Footer from '../navbar/footer';




function Contactus() {
    return (
        <>
            <Navbar />

            <Contactuspage />

            <Footer />

        </>
    )
}

export default Contactus;