import React from 'react';
import './gallery.css';
//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
        
//const banner = 'https://ucarecdn.com/87b1fe31-b4ab-4720-8b58-a53294f994c7/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
const wildlife = 'https://ucarecdn.com/4b567584-d95e-43cb-8f03-06f3eeaa7725/-/preview/960x636/';
const reservationimg = 'https://ucarecdn.com/b79571e9-6ff6-42e4-840c-6496366e4190/-/preview/960x960/';
const curdbike = 'https://ucarecdn.com/ccee9e3a-403a-4ce7-a6a6-3b4e93a1a197/-/preview/960x720/';
const wildlife2 = 'https://ucarecdn.com/5bed923d-6623-47b8-9b66-e1e946cd17c8/-/preview/1000x1000/';
const reservationimg2 = 'https://ucarecdn.com/9bcec59a-427e-45c6-810c-58fac70271f2/-/preview/1000x666/';
const curdbike2 = 'https://ucarecdn.com/fcab5480-f3dd-4e7c-99ea-67e7836b7117/-/preview/1000x750/';
const curdbike3 = 'https://ucarecdn.com/a919e7fd-6c5c-42d9-8276-2a612d76b01a/-/preview/960x640/';
const wildlife3 = 'https://ucarecdn.com/f364fd8b-a903-41dc-9bb6-f3723d368a0b/-/preview/1000x666/';
        
        
function Gallerypage() {
    return (
        <div className="gallerypage">
            <div className='gallerytitle'>
                <h1>Our Gallery</h1>
                <div className='ttleunderline'></div>
            </div>

            <div className='galleryimagesect'>
                <div className='galleryimage'>
                    <img src={wildlife} alt="wildlife" />
                </div>

                <div className='galleryimage'>
                    <img src={reservationimg} alt="wildlife" />
                </div>

                <div className='galleryimage'>
                    <img src={curdbike} alt="wildlife" />
                </div>

                <div className='galleryimage'>
                    <img src={wildlife2} alt="wildlife" />
                </div>

                <div className='galleryimage'>
                    <img src={reservationimg2} alt="wildlife" />
                </div>

                <div className='galleryimage'>
                    <img src={curdbike2} alt="wildlife" />
                </div>

                <div className='galleryimage'>
                    <img src={curdbike3} alt="wildlife" />
                </div>

                <div className='galleryimage'>
                    <img src={wildlife3} alt="wildlife" />
                </div>
            </div>
            
            
             
        </div>
    );
}
        
        
export default Gallerypage;
        