import React from 'react';

import '../navbar/navbar.css';
import Navbar from '../navbar/navbar';

import '../experiences/experience.css';
import Experiencespage from '../experiences/experience';

import '../navbar/footer.css';
import Footer from '../navbar/footer';

import '../aboutus/banner.css';
import Bannersect from '../aboutus/banner';




function Experiences() {
    return (
        <>
            <Navbar />

            <Bannersect />

            <Experiencespage />

            <Footer />

        </>
    )
}

export default Experiences;