import React from 'react';
//import { Link } from 'react-router-dom';
import './home.css';

import '../gallerycara/imagegrid.css';
import Imagegridpic from '../gallerycara/imagegrid';

import '../gallerycara/videogrid.css';
import Videogridhome from '../gallerycara/videogrid';

import '../gallerycara/productcara.css';
import Productcara from '../gallerycara/productcara';

import '../gallerycara/testimonialts.css';
import Testimonialts from '../gallerycara/testimonialts';


const aboutusimg = "https://ucarecdn.com/b6f7960f-9acc-4823-96e0-2978367d61a2/-/preview/500x500/-/quality/smart/-/format/auto/";

const Homepage = () => {
  
  return (
    <>
    <div className="homeabtus">
        <div className="homeabtusImage">
            <div className='hmabtimgsec'>
                <img src={aboutusimg} alt="About us"/>
            </div>
        </div>

        <div className='homeabtusintro'>
            <div className='hmabtparasecti'>
                <h2>Silwane Educational Tours and Safaris</h2>
                <p>At Silwane Education tours and safaris, we believe that education extends far beyond the classroom walls. Our mission is to provide immersive and enriching experiences that blend education with exploration, fostering a deeper understanding and appreciation of the world around us.</p>
                <p>Specializing in educational tours and safaris, we offer a range of meticulously crafted programs designed to ignite curiosity, inspire discovery, and create lifelong memories. Whether you're a student, educator, or lifelong learner, our diverse array of destinations and itineraries cater to all ages and interests.</p>
            </div>
        </div>
      
    </div>

    

    <Productcara />

    <Testimonialts />

    <Videogridhome />

   
    <Imagegridpic />  
   
    </>
  );
};
  
export default Homepage;
  
  