import React from 'react';
import './aboutus.css';
//import logo from 'https://ucarecdn.com/ebf43df5-eac6-4844-8271-ddd87ae637a4/-/preview/500x500/-/quality/smart/-/format/auto/'; // Replace with your actual logo image URL
//https://scontent-ams2-1.xx.fbcdn.net/v/t39.30808-6/241194792_1604791279851848_5898187860639475142_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=5f2048&_nc_ohc=6VgSX-nkqN8Ab4q24Zk&_nc_ht=scontent-ams2-1.xx&oh=00_AfA85FjpR_iMcCGGF9tSzH2lT4sgyRFcradSBUvIecKbAw&oe=662C2423       
//https://ucarecdn.com/87b1fe31-b4ab-4720-8b58-a53294f994c7/-/preview/500x500/-/quality/smart/-/format/auto/
const banner = 'https://ucarecdn.com/239a8aad-92d8-41a7-be58-0cbafcf0bdc7/-/preview/960x652/'; // Replace with your actual logo image UR
        
        
function Bannerp() {
    return (
        <div className="bannerimage">
            <div className='abtuspgbanner'>
                <img src={banner} alt="banner" />
            </div>   
        </div>
    );
}
        
        
export default Bannerp;
        