import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

import Home from './components/mainpage/pages/Home';
import Aboutus from './components/mainpage/pages/Aboutus';
import Experiences from './components/mainpage/pages/Experiences';
import Bookings from './components/mainpage/pages/Bookings';
import Gallery from './components/mainpage/pages/Gallery';
import Contactus from './components/mainpage/pages/Contactus';


const App = () => {
    return (
        <>
            <Router>

                <Routes>
                    <Route path='/' exact element={<Home />} />
                </Routes>

                <Routes>
                    <Route path='/aboutus' exact element={<Aboutus />} />
                </Routes>

                <Routes>
                    <Route path='/experiences' exact element={<Experiences />} />
                </Routes>

                <Routes>
                    <Route path='/bookings' exact element={<Bookings />} />
                </Routes>

                <Routes>
                    <Route path='/gallery' exact element={<Gallery />} />
                </Routes>

                <Routes>
                    <Route path='/contactus' exact element={<Contactus />} />
                </Routes>
 
            </Router>

        </>
       
    )
}

export default App;