import React, {useState, useEffect} from 'react';
//import { Link } from 'react-router-dom';
import './carasoule.css';
//import { Button } from './Button';
//import { NavLink } from "react-router-dom";

/*const images = [
  "https://images.pexels.com/photos/764681/pexels-photo-764681.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  "https://images.pexels.com/photos/1306497/pexels-photo-1306497.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  "https://images.pexels.com/photos/809119/pexels-photo-809119.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToNextSlide = () => {
    const nextIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(nextIndex);
  };

  const goToPrevSlide = () => {
    const prevIndex = (currentIndex + images.length - 1) % images.length;
    setCurrentIndex(prevIndex);
  };

  return (
    <div className="carousel-container">
      <div className="carousel">
        <div className="slide-container">
          <img className="slide" src={images[currentIndex]} alt="" />
        </div>
        <button className="prev" onClick={goToPrevSlide}>
          &#10094;
        </button>
        <button className="next" onClick={goToNextSlide}>
          &#10095;
        </button>
      </div>
    </div>
  );
};

const images = [
  { id: 1, src: "https://images.pexels.com/photos/764681/pexels-photo-764681.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", img : "Welcome to the Silwane Nature Reserve" },
  { id: 2, src: "https://images.pexels.com/photos/1306497/pexels-photo-1306497.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", img : "Primitive Trails" },
  { id: 3, src: "https://images.pexels.com/photos/809119/pexels-photo-809119.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", img : "A part of Africa that embodies the allure" }
];

https://scontent-ams2-1.xx.fbcdn.net/v/t39.30808-6/432407702_821535303323682_1116631022982081106_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=5f2048&_nc_ohc=PkD1ezSwYpkAb7W1VWb&_nc_ht=scontent-ams2-1.xx&oh=00_AfBFI8tmNkA5MfGL6aap9b3vjt2NBUDDzmlSoBHPob7hag&oe=662BF1AE
*/


const images = [
  { id: 1, src: "https://ucarecdn.com/92cd101e-5d4b-4467-b0a6-c73ec2a750d0/-/preview/960x720/", img : "Welcome to the Silwane Nature Reserve" },
  { id: 2, src: "https://ucarecdn.com/2c941ba1-a37c-4867-b035-61367d9cbdcf/-/preview/1000x666/", img : "Primitive Trails" },
  { id: 3, src: "https://ucarecdn.com/4c946994-7c4c-45e8-bfa8-9c8990525fa7/-/preview/1000x666/", img : "A part of Africa that embodies the allure" }
];

const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    // This effect will run when the component mounts
    const startAnimation = () => {
      setAnimate(true);

      // After the animation duration (adjust as needed), reset the animation
      setTimeout(() => {
        setAnimate(false);
      }, 3000); // Adjust this timeout to match your animation duration
    };

    // Start the animation immediately when the component mounts
    startAnimation();

    // Set up an interval to repeat the animation every 5 seconds
    const interval = setInterval(startAnimation, 5000); // Repeat every 5 seconds

    // Clear the interval when the component unmounts to avoid memory leaks
    return () => clearInterval(interval);
  }, []); 

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((activeIndex + 1) % images.length);

    }, 5000);

    return () => clearInterval(interval);

  }, [activeIndex]);

  const goToPrevSlide = () => {
    setActiveIndex(activeIndex === 0 ? images.length - 1 : activeIndex - 1);
    setAnimate(true);
  };

  const goToNextSlide = () => {
    setActiveIndex((activeIndex + 1) % images.length);
    setAnimate(true);
  };

  return (
    <div className="carousel">
      {images.map((image, index) => (
        <div
          key={image.id}
          className={`slide${index === activeIndex ? ' active' : ''}`}
          style={{ transform: `translateX(${(index - activeIndex) * 100}%)` }}
        >
          <img src={image.src} alt={`Slide ${index + 1}`} />
          <p className={animate ? 'caranumberid1' : 'caranumberid2'}>{image.img}</p>
        </div>
      ))}
      <button className="prev" onClick={goToPrevSlide}>
        &#10094;
      </button>
      <button className="next" onClick={goToNextSlide}>
        &#10095;
      </button>
    </div>
  );
};
  
  export default Carousel;
  
  