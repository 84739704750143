import React, {useState, useEffect} from 'react';
//import { Link } from 'react-router-dom';
import './testimonialts.css';



const images = [
  { id: 1, img : "I brought my family to visit my favorite place on earth the decor and the food were excellent and we were spoiled on the game drives incredible sightings and skilled guides. This place is heaven", nme : "@Vusa"},
  { id: 2, img : "Absolute loving my time at Silwane. Stunning views, amazing game drives, the most pleasant and knowledgeable guides, delicious food. But best of all the hospitality from kind, friendly, efficient staff under the management of Spha", nme : "Scott" },
  { id: 3, img : "There are few places we have travel to where the staff provides a level of excellence that exceeds expectations as the did at Silwane. The location is stunning, attention to detail, and just an incredible location", nme : "@Matha" }
];

const Testimonialsts = () => {
  const [activeIndex, setActiveIndex] = useState(0);


  useEffect(() => {
    const interval = setInterval(() => {
      
      if (activeIndex < 2) {
        setActiveIndex((activeIndex + 1));
      }else{
        setActiveIndex(0);
      }

    }, 10000);

    return () => clearInterval(interval);

  }, [activeIndex]);

  const goToPrevSlide = () => {
    setActiveIndex((activeIndex) =>
    activeIndex === 0 ? 0  : activeIndex - 1
    );
  };

  const goToNextSlide = () => {
    setActiveIndex((activeIndex + 1) % images.length);
    setActiveIndex((activeIndex) =>
        activeIndex === 2 ? 0 : activeIndex + 1
    );
    
  };

  return (
    <div className="testimonialcarousel">
        <button className="testimonialprev" onClick={goToPrevSlide}>
            &#10094;
        </button>
        <button className="testimonialnext" onClick={goToNextSlide}>
            &#10095;
        </button>
        {images.map((image, index) => (
            <div className='buffertestismonialsentence' key={image.id}>
                {(index === activeIndex) &&
                    <div className='testismonialsentence'>
                        <p className='testinonialtspara'>{image.img}
                            <br></br>
                            <br></br>
                            <span>{image.nme}</span>
                        </p>
                    </div>
                }                   
            </div>
        ))}
       
       
    </div>
  );
};
  
  export default Testimonialsts;
  